import React from 'react';
import ErrorPage from '../ErrorPage';
import { useTranslation } from 'next-i18next';

const NotFoundPage: React.FC<any> = () => {
  const { t } = useTranslation('errors');

  const notFoundOptions = {
    title: t('404.title'),
    description: t('404.description'),
    image: 'not-found-image.png',
    code: '404',
  };

  return <ErrorPage {...notFoundOptions} />;
};

export default NotFoundPage;
