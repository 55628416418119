import Button from 'components/blocks/Button';
import ErrorLayout from 'components/layouts/Layout/Error';
import { useRouter } from 'next/router';
import React from 'react';
import { useTranslation } from 'next-i18next';

import styles from './ErrorPage.module.scss';

const ErrorPage: React.FC<any> = ({ title, description, image, code }) => {
  const router = useRouter();
  const { t } = useTranslation('errors');

  return (
    <ErrorLayout>
      <div className={styles.errorPage}>
        <div className='container'>
          <div className={styles.errorPage__body}>
            <img
              src='/images/idBilliards-logo.svg'
              className={styles.errorPage__logo}
            />
            <div className={styles.errorPage__list}>
              <div className={styles.errorPage__Imagelist}>
                <img
                  src={`/images/${image}`}
                  className={styles.errorPage__image}
                />
              </div>
              <div className={styles.errorPage__content}>
                <h2 className={styles.errorPage__contentTitle}>{title}</h2>
                <p className={styles.errorPage__contentDescription}>
                  {description}
                </p>
                <div className={styles.errorPage__contentButtonsList}>
                  <button
                    onClick={() => router.back()}
                    className={styles.errorPage__contentButtonBack}
                  >
                    <img src='/images/icons/error-page-arrow-left.svg' alt='' />
                    {t('common.back')}
                  </button>
                  <Button
                    placeholder={t('common.main')}
                    href='/'
                    addStyles={[styles.errorPage__button]}
                  />
                </div>
              </div>
            </div>
            <p className={styles.errorPage__error}>{`${t(
              'common.error'
            )} ${code}`}</p>
          </div>
        </div>
      </div>
    </ErrorLayout>
  );
};

export default ErrorPage;
