import React from 'react';
import { NextPage } from 'next';
import Head from 'next/head';
import favicon from 'public/images/favicon.ico';

interface ErrorLayoutProps {
  children?: any;
  statusCode?: number;
}

const ErrorLayout: NextPage<ErrorLayoutProps> = ({ children }) => (
  <>
    <Head>
      <link rel='shortcut icon' href={favicon} type='image/x-icon' />
      <title>idChess. Machine learning solutions.</title>
      <link rel='shortcut icon' href='#' />
      <link rel='apple-touch-icon' href='#' />
    </Head>

    {children}
  </>
);

ErrorLayout.getInitialProps = async ({ res, err }) => {
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404;
  return { statusCode };
};

export default ErrorLayout;
