import NotFoundPage from 'components/pages/NotFoundPage';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { GetStaticProps } from 'next';

export default NotFoundPage;

export const getStaticProps: GetStaticProps = async props => {
  return {
    props: {
      ...(await serverSideTranslations(props.locale, ['errors'])),
    },
  };
};
